import React from "react"
import "../style/partner.css"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const partnerPage = () => {
    return (
        <Layout>
            <br /><br /><br /><br />
            <div className="container pt-5 pb-5 mob-partner">
                <div className="row">
                    <div className="col-md-12">
                        <p className="partner-p mob-justify lh-2">Dear Partner,<br />Warm Greetings from Aspire Series – A Sumadhura Venture!</p>
                    </div>
                    {/* Paragraph */}
                    <div className="col-md-12 lh-2">
                        <p className="text-justify mob-justify">With several landmark projects in Bengaluru and Hyderabad, namely Sumadhura ⁠⁠⁠Shikharam,
                        Sumadhura ⁠⁠⁠Silver Ripples, Sumadhura Acropolis, Sumadhura Group today is a force to reckon with
                        when it comes to providing luxury lifestyle solutions across price segments in Bengaluru and
                        Hyderabad.
                        <br /><br />
                        Channel Partners is an integral part of Aspire Series’ holistic growth. Partner with us to have access to some of the most luxurious and affordable housing properties in Bengaluru along with dedicated support and unmatched commitment from us.
                        <br /><br />
                        You can be a channel partner with Aspire Series – A Sumadhura Venture if you are a passionate real estate dealer, consultant, marketer, distributor. We will offer you special offers, incentives and regular updates on the project status. You can be a beneficiary of our strong marketing campaigns as well as product portfolio.
                        <br /><br />
                        We offer exciting Channel Partner Schemes to ensure that your association with Aspire Series – A Sumadhura Venture becomes further more rewarding & truly satisfying. Come join us!</p>
                    </div>
                </div>
                <br />
                <div className="row  mob-justify">
                    {/* Forms */}
                    <div className="col-md-6">
                        <input type="text" className="form-control" id="exampleFormControlInput1" id="partner_name" placeholder="Company Name *" /><br />
                        <input type="text" className="form-control" id="exampleFormControlInput1" id="partner_date" placeholder="Date of Incorporation of Company DD/MM/YYYY*" /><br />
                        <input type="text" className="form-control" id="exampleFormControlInput1" id="partner_person" placeholder="Contact Person *" /><br />
                        <input type="text" className="form-control" id="exampleFormControlInput1" id="partner_pan" placeholder="PAN Number of the Company *" /><br />
                        <input type="text" className="form-control" id="exampleFormControlInput1" id="partner_tax" placeholder="Service Tax Registartion Number *" /><br />
                        <input type="text" className="form-control" id="exampleFormControlInput1" id="partner_rera" placeholder="RERA Number *" /><br />
                        <input type="text" className="form-control" id="exampleFormControlInput1" id="partner_email" placeholder="E-mail ID *" /><br />
                        <input type="number" className="form-control" id="exampleFormControlInput1" id="partner_phone" placeholder="Mobile number *" /><br />
                        <input type="number" className="form-control" id="exampleFormControlInput1" id="partner_landline" placeholder="Office Landline" /><br />
                    </div>
                    <div className="col-md-6">
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" id="partner_registered" placeholder="Registered Address"></textarea><br />
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" id="partner_communication" placeholder="Communication Address"></textarea><br />
                        <input type="text" className="form-control" id="exampleFormControlInput1" id="partner_website" placeholder="Web Site URL" /><br />
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" id="partner_msg" placeholder="Message"></textarea><br />
                        <button className="btn btn-dark float-right pl-5 pr-5" id="partner_submit_btn" >SEND</button>

                    </div>
                </div>
            </div>
            <script src="../MailScripts/partner_mail.js"></script>
	    <script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="25390341"></script>
        </Layout>
    )
}

export default partnerPage
